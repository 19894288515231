import {
    GET_SELECTED_DEVICE_LOADING,GET_SELECTED_DEVICE_DATA,ERROR_SELECTED_DEVICE_DATA,
    GET_RECENT_DEVICES, CLEAR_DASHBOARD, ERROR_RECENT_DEVICES,
    GET_TRIPS_GRAPH, ERROR_TRIPS_GRAPH,
    GET_ALL_DEVICES_LOCATIONS, ERROR_ALL_DEVICES_LOCATIONS, GET_DASHBOARD_SUMMARY, ERROR_DASHBOARD_SUMMARY, GET_RECENT_DEVICE_RECORDS_TRIPS, ERROR_RECENT_DEVICE_RECORDS_TRIPS
} from "./constants";

const initialState = {
    loading: true,
    error: '',
    recent_devices: [],
    devRecordsTrips: [],
    graph: [],
    locations: [],
    total_device: 0,
    active_device: 0,
    inprogress_trips: 0,
    completed_trip: 0,
    battery_need_charge: 0,
    battery_full_charge: 0,
    total_user: 0,
    active_user: 0,
    devDetails:[],
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
         case GET_SELECTED_DEVICE_LOADING:
            return {
                ...state,
                loading: true, // Set loading to true when request starts
                error: null // Clear any previous errors
            };
        case GET_RECENT_DEVICES:
            return {
                ...state,
                recent_devices: payload.devices,
                loading: false,
            }
        case GET_RECENT_DEVICE_RECORDS_TRIPS:
            return {
                ...state,
                devRecordsTrips: payload,
            }
        case GET_DASHBOARD_SUMMARY:
            return {
                ...state,
                total_device: payload.total_device,
                active_device: payload.active_device,
                inprogress_trips: payload.on_trip,
                completed_trip: payload.completed_trip,
                battery_need_charge: payload.charge_battery,
                battery_full_charge: payload.full_battery,
                total_user: payload.total_user,
                active_user: payload.active_user
            }
        case GET_TRIPS_GRAPH:
            return {
                ...state,
                graph: payload,
                loading: false
            }
        case GET_ALL_DEVICES_LOCATIONS:
            return {
                ...state,
                locations: payload
            }
            case GET_SELECTED_DEVICE_DATA:
                return {
                    ...state,
                    devDetails: payload,
                    loading: false
                }
        case ERROR_SELECTED_DEVICE_DATA:
        case ERROR_RECENT_DEVICES:
        case ERROR_DASHBOARD_SUMMARY:
        case ERROR_RECENT_DEVICE_RECORDS_TRIPS:
        case ERROR_TRIPS_GRAPH:
        case ERROR_ALL_DEVICES_LOCATIONS:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case CLEAR_DASHBOARD:
            return initialState
        default:
            return state;
    }
}